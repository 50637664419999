.lds-ring {
  display: inline-block;
  position: relative;
  box-sizing: content-box;
  width: 76px;
  height: 76px;
}
.lds-ring__inner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 6px;
  border: 6px solid #ff5d25;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
}
.lds-ring__inner:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring__inner:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring__inner:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ring--global {
  position: fixed;
  z-index: 9999;
  top: 35%;
  left: 50%;
  padding: 6px;
  // background: rgba(255, 255, 255, 0.9);
  // box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  // border-radius: 5px;
  margin: -44px 0 0 -44px;
}
