@import '@/style/variables.less';

@nav-item-padding: 1em;

.page-nav {
  position: relative;
  left: -2.5%;
  height: 100%;
  padding: 0 20px;

  .PriorityNav_Main {
    display: flex;
    height: 100%;
  }

  .nav-list {
    height: 100%;
    font-size: 14px;
    list-style: none;
  }
  .nav-item {
    position: relative;
    height: 100%;
    padding: 0 @nav-item-padding;
    margin: 0 1em;
    font-weight: bold;
  }

  .PriorityNav_Item--more:hover,
  .nav-item.c-popper--open,
  .nav-item--active {
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 4px;
      left: @nav-item-padding;
      right: @nav-item-padding;
      background: @color-theme;
    }
  }
}

.c-popper-content.nav-popper-content {
  padding-left: 0;
  padding-right: 0;
  background: rgba(6, 6, 6, 0.8);
  min-width: 170px;

  .nav-item {
    list-style: none;
    height: 48px;
    padding: 0 20px;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }
}

.nav-menu-dialog {
  background: transparent;

  &.dialog {
    background: rgba(51, 51, 51, 0.98);
    color: #fff;
  }

  .dialog__inner {
    height: 100%;
  }

  .dialog__body {
    padding: 0;
  }
  .nav-list {
    width: 300px;
    padding: 20px 0;
    margin: 0;
    list-style: none;
  }
  .nav-item {
    padding: 10px 45px 10px 10px;
    margin: 0;
    font-weight: 800;
    font-size: 20px;
    position: relative;
    text-align: right;

    a {
      justify-content: flex-end;
    }
  }

  .c-button--border.c-button--gray {
    color: #fff;
    margin-right: 44px;
  }

  .nav-item--active {
    color: @color-theme;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 5px;
      height: 20px;
      margin-top: -10px;
      background: @color-theme;
    }
  }
}

// .app--md {
//   .page-nav {
//     .nav-item {
//       font-size: 12px;
//       margin: 0 10px;
//     }
//   }
// }

// .app--lg {
//   .page-nav {
//     .nav-item {
//       font-size: 14px;
//     }
//   }
// }

.app--md {
  .page-nav {
    .nav-list {
      font-size: 16px;
      max-width: 34em;
      width: 100%;
    }
  }
}
