/* 模态框基础样式 */
.dialog-bg {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 9999;
  transition: opacity 0.3s;
  overflow: auto;
  padding: 40px 0;

  &:before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
    content: '';
  }

  &.dialog--close {
    opacity: 0;
  }

  .c-icon-close {
    font-size: 24px;
  }
}

.dialog {
  display: inline-block;
  vertical-align: middle;
  max-width: 92%;
  position: relative;
  width: auto;
  text-align: left;
  background: #fff;
  border-radius: 5px;
  margin: -20px 0 0 0;
  /*不要绝对居中，接近视觉居中*/
  z-index: 1;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1);
}

.dialog__inner {
  max-height: 100%;
}

.dialog__head-close {
  position: absolute;
  top: 4px;
  right: 2px;
  line-height: normal;
}

.dialog__head {
  position: relative;
  background: #fff;
  border-radius: 5px 5px 0 0;
  z-index: 2;

  &.dialog__head--float {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    background: transparent;
  }
}

.dialog__title {
  font-size: 17px;
  padding: 26px 1em 10px;
  font-weight: bold;
}

.dialog__title--left {
  padding: 20px 20px 0;
  text-align: left;
  width: 100%;
}

.dialog__body {
  padding: 20px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.6;
}

.dialog__footer {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px;
}

.dialog--no-head {
  .dialog__head {
    display: none;
  }
}

.dialog--no-close {
  .dialog__head-close {
    display: none;
  }
}

/*风格样式*/
// 底部滑上来
.dialog.dialog--slide-left {
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  border-radius: 0 !important;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);

  overflow: hidden;
  box-sizing: border-box;
  transition: transform 0.3s;

  &.dialog--close {
    transform: translateX(-100%);
  }
}

.dialog.dialog--slide {
  z-index: 9999;
  position: fixed;
  left: 50%;
  bottom: 0;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  max-width: 10rem;
  padding: 0;
  margin: 0;
  margin-left: -5rem;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px 5px 0 0;

  overflow: hidden;
  box-sizing: border-box;
  transition: transform 0.3s;

  &.dialog--close {
    transform: translateY(100%);
  }

  .dialog__head {
    height: 56px;
    padding: 6px 20px;
    margin-bottom: -10px;
    justify-content: space-between;
  }

  .dialog__title {
    padding: 0;
  }

  .dialog__head-close {
    position: static;
    margin-right: -10px;
  }
}

/*对话框*/
// .dialog--confirm {
//   &.dialog {
//     min-width: 284px;
//   }
//   .dialog__body {
//     padding: 30px 15px 15px;
//   }
//   .dialog__footer {
//     margin: 35px -6px 0;
//   }
//   .dialog__body .dialog__footer {
//     margin-left: -6px;
//     margin-right: -6px;
//     margin-bottom: 0;
//     padding-left: 0;
//     padding-right: 0;
//     width: auto;
//   }
// }

.wzh-dialog--has-outside-btn {
  .dialog {
    margin-top: -80px;

    >.icon-dialog-circle-close {
      bottom: -115px;
    }
  }
}

.app--md {
  .dialog {
    border-radius: 10px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  }

  .dialog__body,
  .dialog__head {
    max-width: 960px;
  }

  .dialog.dialog--slide,
  .dialog__head {
    border-radius: 10px 10px 0 0;
  }

  .c-icon-close {
    font-size: 26px;
  }

  .dialog__head-close {
    top: 10px;
    right: 8px;
  }

  .dialog__title {
    font-size: 32px;
    padding: 32px 30px 0;
    margin-bottom: -10px;
  }

  .dialog__title--left {
    padding: 22px 34px 0;
    font-size: 18px;
  }

  .dialog__body {
    padding: 34px;
    line-height: 1.5;
  }

  .dialog__footer {
    padding: 0 50-16px 34px;
  }
}

.app--lg {
  .dialog__title {
    font-size: 32px;
    padding: 42px 40px 0;
    margin-bottom: -10px;
  }

  .dialog__title--left {
    padding: 38px 50px 0;
    font-size: 22px;
  }

  .dialog__body {
    padding: 50px;
    line-height: 1.5;
  }

  .dialog__footer {
    padding: 0 50px 50px;
  }
}

.small-form-dialog {
  .dialog__title {
    .app--lg & {
      font-size: 22px;
      padding: 38px 40px 0;
    }
  }

  .dialog__title--left {
    .app--lg & {
      padding: 32px 40px 0
    }
  }

  .dialog__body {
    .app--lg & {
      padding: 40px;
    }
  }

  .dialog__footer {
    .app--lg & {
      padding: 0 40px 40px;
    }
  }

  .form__footer {
    padding-top: 10px;
    margin: 0 -5px;

    .app--md & {
      padding-top: 20px;
      margin: 0 -10px;
    }

    .c-button {
      margin: 0 5px;

      .app--md & {
        margin: 0 10px;
        height: 60px;
      }
    }
  }
}