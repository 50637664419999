@bg: rgba(6, 6, 8, .6);
@size: 4px;

.c-popper-content {
  z-index: 99;
  background: @bg;
  box-shadow: 0px 1px 4px 0px rgba(6, 6, 8, 0.3);
  padding: 9px 12px;
  border-radius: 4px;
  line-height: 1.6;
  color: #fff;
}

.c-popper-content__arrow {z-index: 100;}
.c-popper-content__arrow::before {
  width: 0;height: 0;box-sizing: content-box;display: block;
  border: @size transparent solid;
}

.c-popper-content__arrow {
  visibility: hidden;
}

.c-popper-content__arrow::before {
  visibility: visible;
  content: '';
}

.c-popper-content[data-popper-placement^='top'] {
  > .c-popper-content__arrow {
    top: 100%;
    &::before {
      left: -@size;
      border-top-color: @bg;
    }
  }
}

.c-popper-content[data-popper-placement^='bottom'] {
  > .c-popper-content__arrow {
    bottom: 100%;
    &::before {
      left: -@size;
      border-bottom-color: @bg;
    }
  }
}

.c-popper-content[data-popper-placement^='left'] > .c-popper-content__arrow {
  right: -@size;
}

.c-popper-content[data-popper-placement^='right'] > .c-popper-content__arrow {
  left: -@size;
}