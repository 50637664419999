@import '@/style/variables.less';

.common-page-header {
  height: 80px;
  color: @color-main;
  background-color: #ffffff;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  a {
    color: @color-main !important;
  }

  > .page-row {
    max-width: @maxWidth + 120px + 40px;
    padding-left: 0;
    padding-right: 0;
  }
  
  &.common-page-header--white-transparent {
    color: #fff;
    // background-color: rgba(0, 0, 0, 0.3);
    background: transparent;
    box-shadow: 0px 2px 0px 0px rgba(221, 221, 221, 0);
    a {
      color: #fff !important;
    }
  }

  .page-header__back {
    position: relative;
    z-index: 2;
    width: 30px;
    height: 30px;
    margin-left: -10px;
  }

  &.page-header--fixed {
    position: fixed;
    z-index: 101; /* 想覆盖大多数popper */
    left: 0;
    top: 0;
    width: 100%;
  }

  .phone-num {
    margin-right: 10px;
  }

  .logo {
    position: relative;
    z-index: 2;
    padding: 10px 30px 10px 15px;
    .img {
      width: 120px;
      // height: 22px;
      display: block;
    }
  }

  &.common-page-header--mobile {
    height: 48px;
    padding-left: 15px;
    .logo {
      padding: 10px 15px 10px 12px;
      img {
        width: 110px;
        // height: 20px;
      }
    }
  }
}

.page-header {
  .nav-menu-switch {
    position: relative;
    font-size: 20px;
    z-index: 3;
    .c-icon-menu {
      margin: 0;
    }
  }
}

.page-header__right {
  .c-icon {
    font-size: 22px;
  }
  .c-icon,
  .c-spinner {
    margin-right: 22px;
  }
}

.app--md {
  .page-header__right {
    .c-icon {
      font-size: 24px;
    }
    .c-icon,
    .c-spinner {
      margin-right: 24px;
    }
  }
}

.app--lg {
  .page-header__right {
    .c-icon {
      font-size: 30px;
    }
  }

  .common-page-header {
    .logo {
      padding: 10px 30px;
      .img {
        width: 160px;
        // height: 28px;
      }
    }
  }
}
