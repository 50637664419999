.react-tabs__tab-panel {
  display: none;
}
.react-tabs__tab-panel--selected {
  display: block;
}

.carnex-tab {
  line-height: 2.42em;
  list-style: none;
  font-size: 19px;
}

.carnex-tab__item {
  position: relative;
  font-weight: bold;
  cursor: pointer;
  color: @color-gray;

  &.carnex-tab__item--active {
    color: @color-main;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: @color-theme;
    }
  }
}

.carnex-tab--scroll.scroll-box {
  width: 100%;
  padding: 0 16px 20px;
  margin-bottom: -20px;
  flex-wrap: nowrap;
  gap: 24px;

  .carnex-tab__item {
    white-space: nowrap;
    margin: 0 auto;
  }
}


.carnex-tab--vertical {
  .carnex-tab__item {
    padding-right: 30px;
  }

  .carnex-tab__item--active {
    &::before {
      content: '';
      width: 4px;
      height: 22px;
      background: @color-theme;
      position: absolute;
      top: 50%;
      right: 0;
      left: auto;
      bottom: auto;
      margin-top: -11px;
    }
  }
}

.app--lg {
  .carnex-tab {
    font-size: 28px;
    line-height: 3.2143em;
  }
}
