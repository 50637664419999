._fl(){float: left;display: inline;}
._fr(){float: right;display: inline;}
._cf(){
  &:before,&:after {content:"";display:table;}
  &:after {clear:both;}
  & {zoom:1;}
}
._st (...){
  text-align:center;overflow: hidden;line-height: normal;
  &:after {content:' ';vertical-align:middle;/*add---*/height:100%;width:0;display: inline-block;/*---add*/}
}

.px2rem(@name, @px) {
  @{name}: @px / @remSize * 1rem;
}

.placeholder(@color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: @color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: @color;
   opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: @color;
   opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: @color;
  }
}
._text-overflow() {white-space: nowrap;overflow: hidden;text-overflow:ellipsis;}
._paragraph-overflow(@num: 2) {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @num;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: normal;
}

._text-justify() {
	width: 4em;
	display:inline-block;
	text-align: justify;
	vertical-align:top;

	&:after{
    content:".";
    display: inline-block;
    width:100%;
    overflow:hidden;
    height:0;
	}
}

.function {
	.px2rem(@px) {
		return: @px / @remSize * 1rem;
	}

	.px2em(@px, @ref: 14) {
		/* 页面基础字体大小14，设计稿为2x */
		return: @px / (@ref*2) * 1em;
	}

	.usepx(@px) {
		return: @px ~"/*force*/";
	}
}
