.google-login-button {
  img {
    width: 22px;
    height: 22px;
    margin-right: 12px
  }
}

.app--md {
  .google-login-button {
    height: 60px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 20px
    }
  }

}