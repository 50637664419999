@import "./_variables.less";

/*缩写*/
.block {display: block;}
.inline-block {display: inline-block;}
.pos-r {position: relative;}
.pos-a {position: absolute; top: 0; left: 0;}
.fl {float: left;}
.fr {float: right;}
.cf {._cf();}
.cursor-pointer {cursor: pointer;}
.cursor-default {cursor: default;}
.point-through {pointer-events: none;}
.display-none {display: none;}
.visibility-hidden {visibility: hidden;}
.overflow {overflow: hidden;}
.no-linefeed {white-space: nowrap;}
.auto-linefeed {white-space: normal;}
.pre-box {white-space: pre-wrap;}
.underline {text-decoration: underline;}
.text-left {text-align: left;}
.text-center {text-align: center;}
.text-right {text-align: right;}
.text-del {text-decoration: line-through;}
.text-underline {text-decoration: underline;}
.text-bold {font-weight: bold;}
.text-regular {font-weight: 400;}
.text-overflow {._text-overflow();}
.text-justify {text-align: justify;text-justify: auto;}
.unselectable {user-select: none;}
.selecttext {user-select: text;}
.list-style-none {list-style: none;}
.rotate180 {transform: rotate(180deg);}
.rotate-45 {transform: rotate(-45deg);}
.rotate-90 {transform: rotate(-90deg);}
.fit-contain {object-fit: contain;}
.fit-cover {object-fit: cover;}

@media print {
  .hide-in-print {
    display: none;
  }
}

/*功能块*/
.full-width {width: 100%;}
.full-height {height: 100%;}
.full-size {width: 100%;height: 100%;}
.max-full-width {max-width: 100%;}
.min-full-width {min-width: 100%;}
.min-full-height {min-height: 100%;}
.max-full-height {max-height: 100%;}
.box-center {margin-left: auto;margin-right: auto;}
.ratio-box {position: relative;padding-top: 100%;width: 100%;}
.horizontal-block-container {display: inline-block;white-space: nowrap;}
.paragraph-overflow {._paragraph-overflow();}
.paragraph-overflow-3 {._paragraph-overflow(3);}
.paragraph-overflow-4 {._paragraph-overflow(4);}
.paragraph-overflow-5 {._paragraph-overflow(5);}
.scroll-box {-webkit-overflow-scrolling: touch;overflow: auto;}
.hairlines {
  .border-1px, .border-1px--after::after, .border-1px--before::before {border-width: .5px !important;}
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

/* html容器的公共样式 */
.html-container {
  // font-size: 15px;/* no */
  text-align: justify;
  text-justify: auto;
  word-break:normal;
  word-wrap:break-word;
  transform: translateZ(0);

  img {max-width: 100%;}
  ol, ul {list-style-position: inside;}
}

.html-container--no-transform {
  transform: none;
}

.text-container {
  text-align: justify;
  text-justify: auto;
  word-break:normal;
  word-wrap:break-word;
}

.common-fix-bottom {position: fixed;bottom: 0;}
.common-fix-full {width: 100%;max-width: 375px;left: 50%;margin-left: -187.5px;}
.ipx-safe-bottom {bottom: 0;}

#safeArea() {
  .bottom() {
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
  }
  .height() {
    height: constant(safe-area-inset-bottom);
    height: env(safe-area-inset-bottom);
  }
}

@supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
  .page-scroll-box {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);

    &::after {
      content: '';
      position: fixed;
      bottom: 0;
      width: 100%;
      height: constant(safe-area-inset-bottom);
      height: env(safe-area-inset-bottom);
      background-color: #fff;
      z-index: 999999;
    }
  }

  .clear-ipx-patch {
    padding-bottom: 0;
    &::after {content: none;}
  }

  html {
    .common-fix-bottom,
    .ipx-safe-bottom {
      #safeArea.bottom();
    }
  }
}