@import '~@/style/variables.less';

.c-radio {
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid @color-border;
  padding: 10px 15px;
  min-height: 45px;
  transition: color 0.3s, border-color 0.3s, box-shadow 0.3s;
  line-height: 1.3;
}

.c-radio__indicator {
  position: relative;
  margin-right: 8px;
  width: 15px;
  height: 15px;
  background: @color-bg-gray2;
  border: 1px solid @color-border;
  border-radius: 50%;
  transition: background-color 0.3s, border-color 0.3s;

  &::after {
    content: '';
    display: block;
    font-size: 0;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: rgba(244, 244, 244, 0);
    transition: background-color 0.3s, box-shadow 0.3s;
  }
}

.c-radio--selected {
  color: @color-theme;
  border-color: @color-theme;
  box-shadow: 0px 0px 8px 0px rgba(255, 93, 37, 0.3);

  .c-radio__indicator {
    border-color: @color-theme;
    background-color: @color-theme;

    &::after {
      box-shadow: 0px 2px 6px 0px #c83300;
      background-color: #fff;
    }
  }
}

.radio-group--horizontal {
  margin: 0 -10px;
  .c-radio {
    margin: 0 10px;
  }
}

.radio-group--vertical {
  .radio-col,
  .c-radio {
    margin-top: 15px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.app--md {
  .c-radio {
    padding: 8px 20px;
    min-height: 60px;
  }
  .radio-group--vertical {
    .radio-col,
    .c-radio {
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .c-radio__indicator {
    margin-right: 10px;
    width: 20px;
    height: 20px;

    &::after {
      width: 10px;
      height: 10px;
    }
  }
}

.c-radio-input.c-form-item-wrap {
  margin-left: 12px;
  color: @color-main;
  .c-form-item {
    min-height: 35px;
    background: @color-bg-gray;
  }
}

.app--md {
  .c-radio-input.c-form-item-wrap {
    .c-form-item {
      min-height: 35px;
    }
  }
}
