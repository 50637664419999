// stripe element 样式

@inputPadding: 15px;
.flex-StripeElement {
  padding: @inputPadding;
  > div {
    width: 100%;
  }
}

.app--md {
  @inputPadding: 20px;
  .flex-StripeElement {
    padding: @inputPadding;
  }
}
