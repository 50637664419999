@import '~@/style/variables.less';

.login-tab {
  background: #fff;

  .react-tabs__tab-panel--selected {
    display: flex;
  }
}

.login-dialog__head {
  padding: 0 20px 0 40px;
  border-bottom: 1px solid @color-border;

  .carnex-tab {
    font-size: 17px;
    line-height: 3.636em;
  }

  .carnex-tab__item {
    margin-left: 30px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.login-dialog__body {
  font-size: 12px;
  padding: 23px 15px 30px;

  .c-button {
    font-size: 12px;
  }
}

.login-dialog {
  .dialog__body {
    padding: 0 !important;
    max-width: none;
  }

  .login-dialog__body {
    max-width: 345px;
  }
}

.app--md {
  .login-dialog {
    .login-dialog__body {
      width: 520px;
      max-width: 100%;
    }
  }

  .login-dialog__head {
    .carnex-tab {
      font-size: 22px;
    }

    .carnex-tab__item {
      margin-left: 60px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .login-dialog__body {
    font-size: 16px;
    padding: 30px 40px 40px;

    .c-button {
      font-size: 16px;
    }
  }
}