.pin-box-v2 {
  .pin-box-placeholder {
    visibility: hidden;
  }

  .pin-box-elm {
    position: absolute;
    top: 0;
    // left: 50%;
    width: 100%;
    // max-width: 375px;
    // transform: translateX(-50%) translateY(0);
  }

  .pin-box-elm--fix {
    position: fixed;
    z-index: 10;
  }

  .pin-box-elm--pad {
    max-width: 900px;
    /* no */
  }

  .pin-box-elm--full {
    max-width: none;
    margin-left: 0;
    left: 0;

    &.pin-box-elm {
      transform: none;
    }
  }

  .pin-box-elm--pad-position-auto {
    left: auto;
    transform: none;
  }

  .pin-box-elm--limit {
    transition: none;
    // transform: translateX(-50%) translateY(0);
    position: absolute;
  }

  &.pin-box--inverse {
    .pin-box-elm {
      top: auto;
      bottom: 0;
    }

    .pin-box-elm--limit {
      position: absolute;
      // transform: translateX(-50%) translateY(0);
    }
  }
}

.pin-box-bottom-mode {
  .pin-box-elm--fix {
    bottom: 0;
    top: auto;
  }
}