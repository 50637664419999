.login-dialog {
  background: transparent;
  max-width: none;
  overflow: hidden;
}

.login-invite-activity {
  background: #fef1ec url('../../static/coupon/pc-login.png');

  .login-invite-activity-content {
    width: 522px;
    height: 697px;
    padding: 256px 0 0;
  }

  +div .login-dialog__body {
    max-height: 616px;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }
}

.login-invite-activity--mobile {
  background: url('../../static/coupon/mobile-login.png');
  background-size: cover;
  width: 363px;
  height: 462px;

  .login-invite-activity-content {
    padding: 114px 0 20px;

    &,
    .self__invite--subtitle {
      font-size: 12px;
    }

    .self__invite-title {
      font-size: 18px;
    }

    .self__invite--p {
      padding: 17px 20px 32px;
      line-height: 1.66;
    }
  }

  .c-button {
    width: 198px;
    height: 33px;
    margin: 14px auto 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.login-invite-activity-content {
  font-size: 16px;


  .self__invite-title,
  .self__invite--subtitle {
    color: #D13905;
  }

  .self__invite-title {
    font-size: 28px;
    padding-bottom: 6px;
  }

  .self__invite--subtitle {
    font-size: 16px;
  }

  .self__invite--p {
    padding: 38px 94px 47px;
    line-height: 2;
  }
}