@import '~@/style/variables.less';

.time-list {
  background: #fff;
  list-style: none;
  width: 320px;
  padding: 10px 6px;
  border-radius: 5px;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.2);
}
.time-list__item {
  width: 90px;
  border: 1px solid @color-border;
  margin: 6px;
  text-align: center;
  padding: 4px 8px;
  cursor: pointer;
}

.time-list__item--selectd {
  background: @color-theme;
  border-color: @color-theme;
  color: #fff;
}
