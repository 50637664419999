@import './variables.less';

/*颜色*/
.color-white {color: #fff;}
.color-black {color: #000;}
.color-main {color: @color-main;}
.color-main2 {color: @color-main2;}
.color-gray {color: @color-gray;}
.color-gray2 {color: @color-gray2;}
.color-green {color: @color-green;}
.color-green2 {color: @color-green2;}
.color-theme {color: @color-theme;}
.color-border {color: @color-border;}
.color-red {color: #f13c3c;}
.bg-white {background-color: #fff;}
.bg-green {background-color: @color-green;}
.bg-theme {background-color: @color-theme;}