@import '@/style/variables.less';

.site-announcement {
  background: @color-main;
  display: block;
  color: #fff !important;
  height: 44px;
  font-size: 12px;

  .app--md & {
    font-size: 16px;
  }

  &.site-announcement--green {
    background: @color-green3;
  }

  &.site-announcement--theme {
    background: @color-theme;
  }

  &.site-announcement--theme-shallow {
    background: @color-theme-shallow;
    color: @color-theme !important;
  }


  >.page-row {
    position: relative;
    max-width: @maxWidth + 120px + 40px;
  }

  .c-icon-message {
    margin-right: 6px;
    font-size: 16px;
  }

  .site-announcement__link {
    margin-left: 0.4em;
  }

  .c-icon-close {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -8px;
  }
}