@import '@/style/variables.less';

.c-checkbox {
  padding: 4px 0;
  .c-icon-checked, .c-icon-unchecked {font-size: 24px;}
  .c-icon-unchecked {color: #666;}
}

.c-checkbox__icon {
  margin-right: 7px;
}
.c-checkbox__original {
  opacity: 0;
  outline: none;
  position: absolute;
  margin: 0;
  width: 0;
  height: 0;
  z-index: -1;
}