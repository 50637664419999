@import '~@/style/variables.less';

.hr-div {
  padding: 1em 0;
  &::after, &::before {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background: @color-border;
    margin-right: 2em;
  }

  &::after {
    margin: 0 0 0 2em;
  }
}

.paragraph {
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
}

.app--md {
  .paragraph {
    margin-top: 30px;
  }
}
