/*字体 - 相对单位*/
.fs8 {font-size: 8px;}
.fs10 {font-size: 10px;}
.fs11 {font-size: 11px;}
.fs12 {font-size: 12px;}
.fs13 {font-size: 13px;}
.fs14 {font-size: 14px;}
.fs15 {font-size: 15px;}
.fs16 {font-size: 16px;}
.fs17 {font-size: 17px;}
.fs18 {font-size: 18px;}
.fs19 {font-size: 19px;}
.fs20 {font-size: 20px;}
.fs21 {font-size: 21px;}
.fs22 {font-size: 22px;}
.fs24 {font-size: 24px;}
.fs26 {font-size: 26px;}
.fs28 {font-size: 28px;}
.fs30 {font-size: 30px;}
.fs32 {font-size: 32px;}
.fs36 {font-size: 36px;}
.fs40 {font-size: 40px;}
.fs42 {font-size: 42px;}

/*字体 - 绝对单位*/
.fz10 {font-size: 10px;/* no */}
.fz12 {font-size: 12px;/* no */}
.fz13 {font-size: 13px;/* no */}
.fz14 {font-size: 14px;/* no */}
.fz15 {font-size: 15px;/* no */}
.fz16 {font-size: 16px;/* no */}
.fz17 {font-size: 17px;/* no */}
.fz18 {font-size: 18px;/* no */}
.fz19 {font-size: 19px;/* no */}
.fz20 {font-size: 20px;/* no */}
.fz22 {font-size: 22px;/* no */}
.fz24 {font-size: 24px;/* no */}
.fz26 {font-size: 26px;/* no */}
.fz28 {font-size: 28px;/* no */}
.fz30 {font-size: 30px;/* no */}
.fz32 {font-size: 32px;/* no */}
.fz36 {font-size: 36px;/* no */}
.fz40 {font-size: 40px;/* no */}
.fz42 {font-size: 42px;/* no */}

/*字体*/
.ff-ms {font-family: 'Microsoft YaHei';}
.ff-arial {font-family: Arial;}
.ff-system {font-family: Arial, sans-serif, 'Microsoft YaHei';}

/*行高*/
.lh1-1 {line-height: 1.1;}
.lh1-4 {line-height: 1.4;}
.lh1-5 {line-height: 1.5;}
.lh1-6 {line-height: 1.6;}
.lh1-8 {line-height: 1.8;}