.login-panel {
  .c-button {
    font-size: 12px;
  }
  .form {
    margin-top: -10px;
  }
}

.app--md {
  .login-panel {
    .c-button {
      font-size: 16px;
    }
  }
}
