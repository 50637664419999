@import '@/style/variables.less';

@inputPadding: 15px;

.c-form-item-wrap {
  margin-bottom: 6px;
  .c-form-item__error {
    padding: 0;
  }

  .currency-prefix {
    padding-left: 20px;
  }
}

.c-popper-content,
.calendar-dialog {
  .Calendar__day.-selected,
  .Calendar__day.-selectedStart,
  .Calendar__day.-selectedEnd {
    background: @color-theme;
  }

  .Calendar__day.-selectedBetween {
    color: @color-theme;
    background: fade(@color-theme, 20%);
  }
}

.calendar-dialog {
  &.dialog {
    .dialog__head-close {
      position: absolute;
      top: 12px;
      right: 12px;
      margin: 0;
      z-index: 11;
    }

    .dialog__head {
      position: static;
      padding: 0;
      margin: 0;
      height: 0;
    }

    .dialog__body {
      padding: 0;
    }
  }

  .Calendar {
    width: 100%;
    max-width: 9.4rem;
    box-shadow: none;
    background: transparent;
    z-index: 0;
    padding-top: 0px;
    margin: 0 auto;
  }
  .Calendar__header {
    padding-left: 16px;
    padding-right: 16px;
  }
  .Calendar__weekDays,
  .Calendar__section {
    padding-left: 10px;
    padding-right: 10px;
  }

  .Calendar__section.-hiddenPrevious {
    transform: translateX(-100%);
  }
  .Calendar__section.-hiddenNext {
    transform: translateX(100%);
  }
}

.c-form-item-wrap--error {
  .c-form-item {
    background: #ffeaea;
  }
}
.c-form-item-wrap--custom {
  .c-form-item {
    background: transparent;
    min-height: 0;
  }
}

.c-form-item-wrap--checkbox {
  + .c-form-item__error {
    padding-top: 0;
  }
}

.c-form-item-wrap--text {
  padding-top: 4px;
  .c-form-item {
    min-height: 0 !important;
    background: transparent;
  }
}

.c-form-item__label-wrap--hide {
  flex: none;
  width: 0;
  white-space: nowrap;
  visibility: hidden;
  overflow: hidden;
}
.c-form-item__label {
  padding: 8px 0;
  > .flex {
    transition: color 0.3s, transform 0.3s;
    transform: translateY(0) scale(1);
    transform-origin: 0 0;
  }
}

.c-form-item {
  background: @color-bg-gray;
  min-height: 45px;
  font-size: 12px;

  .c-form-item__label {
    padding: 0 0 0 @inputPadding;
  }

  // radio
  .c-radio__label-desc {
    font-size: 12px;
    color: @color-gray;
    padding-top: 2px;
    line-height: 1.2;
  }
  .c-radio--selected {
    .c-radio__label-desc {
      color: inherit;
      opacity: 0.8;
    }
  }
}

.c-form-item__control {
  position: relative;
  z-index: 2;

  > *,
  .DatePicker__input,
  .c-select__control {
    width: 100%;
    height: 100%;
  }
  input,
  textarea {
    max-width: 100%;
    background: transparent;
    border: none;
    outline: 0;
    padding: 0 @inputPadding;
    font-size: inherit;

    &:disabled {
      cursor: not-allowed;
    }
  }

  textarea {
    line-height: 1.5;
    height: 10.6em;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }
}

.c-form-item__suffix-icon {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  min-width: 32px;
  .c-icon-drop-down {
    font-size: 16px;
  }
  .c-icon-select {
    font-size: 32px;
  }
}

.c-form-item {
  .c-select .c-select__value-container {
    padding-right: 2em;
    padding-left: @inputPadding;
  }
}

.c-form-field__label {
  padding-right: 0.2em;
}

// 下划线样式
.c-form-item-wrap.c-form-item-wrap--label-line {
  &.c-form-item-wrap--focus,
  &.c-form-item-wrap--has-value {
    .c-form-item__label {
      > .flex {
        color: @color-gray;
        transform: translateY(-1em) scale(0.8);
      }
    }
  }

  .c-form-item {
    position: relative;
    background: transparent;
    border-bottom: 1px #ddd solid;
    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 0;
      height: 1px;
      background: @color-theme;
      transition: width 0.3s;
    }
    .c-form-item__label {
      padding-left: 10px;
      z-index: 4;
      pointer-events: none;
    }
    .c-form-item__control {
      input {
        padding: 0.9em 10px 0;
      }
    }
  }
}

.c-popper-content.calendar-popper-content,
.c-popper-content.form-item-popper-content {
  @bg: #fff;
  @size: 8px;
  @space: 20px;

  color: @color-main;
  background: @bg;
  padding: 20px;
  overflow: visible;
  box-shadow: 0px 2px 18px 3px rgba(6, 6, 8, 0.18);

  .form-item-popper-content__offset-space {
    margin: 0 -@space;
  }

  .c-popper-content__arrow::before {
    border-width: @size;
  }

  &[data-popper-placement^='top'] {
    > .c-popper-content__arrow {
      &::before {
        left: -@size;
        border-top-color: @bg;
      }
    }
  }

  &[data-popper-placement^='bottom'] {
    > .c-popper-content__arrow {
      &::before {
        left: -@size;
        border-bottom-color: @bg;
      }
    }
  }
}

.c-popper-content.calendar-popper-content {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.app--md {
  @inputPadding: 20px;
  .c-form-item-wrap {
    margin-bottom: 10px;
  }
  
  .c-form-item {
    min-height: 60px;
    font-size: 16px;
    .c-form-item__label {
      padding: 0 0 0 @inputPadding;
    }
    .c-radio__label-desc {
      font-size: 14px;
    }
  }
  .c-form-item__control {
    input {
      padding: 0 @inputPadding;
    }
  }

  .c-form-item__label {
    font-size: 16px;
  }
}
