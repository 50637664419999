@import '@/style/variables.less';

.app--not-md {
  .page-footer--mobile {
    width: 10rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.page-footer {
  font-size: 14px;
  padding-top: 20px;
  background: #fff;
  border-top: 2px solid @color-border;
  position: relative;

  .footer-nav-wrap {
    margin: 0 -20px;
  }
  .footer-nav {
    padding: 20px;
  }
  .footer-nav__title {
    padding-bottom: 10px;
  }

  .logo-col {
    padding-top: 35px;

    .page-footer__content-item {
      font-size: 16px;
    }
  }
  .logo-wrap {
    padding-bottom: 13px;
  }
  .logo {
    width: 120px;
    height: 21px;
  }

  address {
    font-style: normal;
  }
  .footer-icon-item {
    line-height: 1.6;
    .footer-icon-item__icon {
      width: 16px;
      height: 1.6em;
      margin-right: 7px;
    }
    .c-icon {
      width: 16px;
      height: 16px;
    }
  }

  .page-footer__content-item,
  .footer-icon-item {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0.8em;
  }
  .footer-icon-item {
    > .flex-item > .page-footer__content-item {
      padding-top: 0;
    }
  }

  .social-media__box {
    margin: 0 -10px 20px;
  }
  .social-media__item {
    width: 48px;
    height: 48px;
    margin: 10px;
    border: 1px solid @color-theme;
    border-radius: 12px;
    font-size: 28px;
  }
}

.page-footer__row {
  max-width: 1540px;
  padding: 0 20px;
  margin: 0 auto;
}
.page-footer__copyright {
  padding: 10px 0;
  font-size: 14px;

  &.flex--col {
    .logo-wrap {
      padding: 20px 0;
    }
  }

  .omvic-img {
    width: 78px;
    height: 26px;
  }
}

.app--sm {
  .page-footer__copyright {
    border-top: 2px solid @color-border;
    margin-top: 30px;
    padding: 30px 0;
  }
}

.app--md {
  .page-footer {
    font-size: 16px;
    padding-top: 50px;

    .footer-nav__title {
      font-size: 22px;
    }

    .footer-icon-item {
      .footer-icon-item__icon {
        margin-right: 11px;
      }
    }

    .page-footer__content-item,
    .footer-icon-item {
      padding-right: 0;
    }

    .page-footer__row {
      padding-left: 60px;
      padding-right: 60px;
    }
    .logo-col {
      padding-top: 30px;
    }
    .logo-wrap {
      padding-bottom: 50px;
    }
    .logo {
      width: 228px;
      height: auto;
      max-width: 100%;
    }
    .social-media__box {
      margin-bottom: 0;
    }
  }
}
