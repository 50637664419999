/*间隔*/
.pt5 {padding-top: 5px;}
.pt10 {padding-top: 10px;}
.pt12 {padding-top: 12px;}
.pt15 {padding-top: 15px;}
.pt20 {padding-top: 20px;}
.pl20 {padding-left: 20px;}
.pb5 {padding-bottom: 5px;}
.pb10 {padding-bottom: 10px;}
.pb12 {padding-bottom: 12px;}
.pb15 {padding-bottom: 15px;}
.pb20 {padding-bottom: 20px;}
.mt4 {margin-top: 4px;}
.mt5 {margin-top: 5px;}
.mt6 {margin-top: 6px;}
.mt8 {margin-top: 8px;}
.mt10 {margin-top: 10px;}
.mt12 {margin-top: 12px;}
.mt15 {margin-top: 15px;}
.mt20 {margin-top: 20px;}
.mt30 {margin-top: 30px;}
.mr4 {margin-right: 4px;}
.mr6 {margin-right: 6px;}
.mr8 {margin-right: 8px;}
.mr10 {margin-right: 10px;}
.mr12 {margin-right: 12px;}
.mr15 {margin-right: 15px;}