@import '@/style/variables.less';

.c-button {
  color: #fff;
  background: @color-theme;
  border-radius: 2px;
  border: none;
  padding: 0 16px;
  height: 45px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  .lds-ring {
    width: 34px;
    height: 34px;
  }
  .lds-ring__inner {
    width: 22px;
    height: 22px;
    border-width: 3px;
    border-color: #fff;
  }

  &.c-button--disabled,
  &.c-button--loading,
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  &.c-button--white {
    background: #fff;
    color: @color-main;
    border: 1px solid @color-border;
    .lds-ring__inner {
      border-color: @color-main;
    }
  }

  &.c-button--white2 {
    background: #fff;
    color: @color-theme;

    .lds-ring__inner {
      border-color: @color-theme;
    }
  }

  &.c-button--text {
    background: transparent;
    color: @color-main;
  }

  &.c-button--gray {
    background: fadeout(@color-gray, 84%);
    color: @color-main;
  }
}

.c-button--medium {
  height: 33px;
  font-size: 12px;
  min-width: 120px;
}

.c-button--border {
  color: @color-theme;
  background: transparent;
  border-radius: 2px;
  border: 1px solid @color-theme;
  .lds-ring__inner {
    border-color: @color-theme;
  }

  &.c-button--gray {
    background: transparent;
    border-color: @color-border;
  }
}

.app--md {
  .c-button {
    height: 50px;
    padding: 0 22px;

    .lds-ring {
      width: 45px;
      height: 45px;
    }
    .lds-ring__inner {
      width: 30px;
      height: 30px;
      border-width: 4px;
    }
  }

  .c-button--medium {
    height: 44px;
    font-size: 16px;
    min-width: 160px;
  }
}
