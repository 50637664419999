@import './variables.less';

/* select样式 */
.c-select {
  cursor: pointer;

  .c-select__control {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  .c-select__single-value {
    margin-left: 0;
    margin-right: 0;
  }

  .c-select__value-container {
    padding-left: 20px;
  }

  .c-select__indicators {
    display: none;
  }
}

body {
  a:hover {
    color: inherit;
  }

  .c-select__menu {
    // background: @color-main;
    background: #363a46;
    color: #fff;
    text-align: center;
    font-weight: normal;
    z-index: 10;
    .c-select__option {
      background: transparent;
      cursor: pointer;
    }
    .c-select__option--is-selected,
    .c-select__option:active {
      background: rgba(0, 0, 0, 0.2);
      font-weight: bold;
    }
    .c-select__option--is-focused {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .c-select__menu-portal {
    z-index: 10;
  }
}
