@font-face {
  font-family: "iconfont"; /* Project id 3225972 */
  src: url('iconfont.woff2?t=1720942499649') format('woff2'),
       url('iconfont.woff?t=1720942499649') format('woff'),
       url('iconfont.ttf?t=1720942499649') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.c-icon-message:before {
  content: "\e694";
}

.c-icon-unchecked2:before {
  content: "\e601";
}

.c-icon-checked2:before {
  content: "\e630";
}

.c-icon-left:before {
  content: "\e661";
}

.c-icon-email:before {
  content: "\e662";
}

.c-icon-right:before {
  content: "\e663";
}

.c-icon-select:before {
  content: "\e664";
}

.c-icon-heart--hollow:before {
  content: "\e665";
}

.c-icon-hook:before {
  content: "\e666";
}

.c-icon-time:before {
  content: "\e667";
}

.c-icon-test-drive:before {
  content: "\e668";
}

.c-icon-location:before {
  content: "\e669";
}

.c-icon-twitter:before {
  content: "\e66a";
}

.c-icon-youtube:before {
  content: "\e66b";
}

.c-icon-report:before {
  content: "\e66c";
}

.c-icon-microphone:before {
  content: "\e66d";
}

.c-icon-user:before {
  content: "\e66e";
}

.c-icon-close:before {
  content: "\e66f";
}

.c-icon-ins:before {
  content: "\e670";
}

.c-icon-territory:before {
  content: "\e671";
}

.c-icon-360:before {
  content: "\e672";
}

.c-icon-in-outreport:before {
  content: "\e673";
}

.c-icon-facebook:before {
  content: "\e674";
}

.c-icon-passed:before {
  content: "\e675";
}

.c-icon-exclamation:before {
  content: "\e676";
}

.c-icon-menu:before {
  content: "\e677";
}

.c-icon-heart:before {
  content: "\e678";
}

.c-icon-checked:before {
  content: "\e648";
}

.c-icon-unchecked:before {
  content: "\e652";
}

.c-icon-drop-down:before {
  content: "\e679";
}

.c-icon-search:before {
  content: "\e67a";
}

.c-icon-sort:before {
  content: "\e67b";
}

.c-icon-filter:before {
  content: "\e67c";
}

.c-icon-phone:before {
  content: "\e660";
}

.c-icon-upload:before {
  content: "\e67d";
}

.c-icon-del:before {
  content: "\e718";
}

.c-icon-add:before {
  content: "\eb7f";
}

.c-icon-eye:before {
  content: "\e8bf";
}

.c-icon-info:before {
  content: "\e636";
}

.c-icon-edit:before {
  content: "\e818";
}

.c-icon-hot-fill:before {
  content: "\e781";
}

.c-icon-enlarge:before {
  content: "\e6be";
}

.c-icon-youtube-line:before {
  content: "\e880";
}

.c-icon-calendar:before {
  content: "\e67e";
}

.c-icon-left-fine:before {
  content: "\e628";
}

.c-icon-right-fine:before {
  content: "\e642";
}

