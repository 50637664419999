@import '~@common/style/base/_variables.less';
@import '~@common/style/base/_reset.less';
@import '~@common/style/base/_space.less';
@import '~@common/style/base/_font.less';
@import '~@common/style/base/_flex.less';
@import '~@common/style/base/_base.less';
@import './variables.less';
@import './color.less';
@import './article.less';
@import './tab.less';
@import './addon.less';
@import './form.less';

@font-face {
  font-family: Avenir;
  src: url('../fonts/Avenir/AvenirNextLTPro-Light.woff') format('woff'),
    url('../fonts/Avenir/AvenirNextLTPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Avenir;
  src: url('../fonts/Avenir/AvenirNextLTPro-Regular.woff') format('woff'),
    url('../fonts/Avenir/AvenirNextLTPro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Avenir;
  src: url('../fonts/Avenir/AvenirNextLTPro-Medium.woff') format('woff'),
    url('../fonts/Avenir/AvenirNextLTPro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Avenir;
  src: url('../fonts/Avenir/AvenirNextLTPro-Demi.woff') format('woff'),
    url('../fonts/Avenir/AvenirNextLTPro-Demi.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Avenir;
  src: url('../fonts/Avenir/AvenirNextLTPro-Bold.woff') format('woff'),
    url('../fonts/Avenir/AvenirNextLTPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

.ff-normal {
  font-family: Avenir, Arial, sans-serif, 'Microsoft YaHei';
}

.ff-book {
  font-family: Avenir-Book, Avenir, Arial, sans-serif, 'Microsoft YaHei';
}

.ff-black,
.text-bold,
b,
strong,
.c-button {
  font-family: Avenir-Black, Avenir, Arial, sans-serif, 'Microsoft YaHei';
}

.ff-heavy {
  font-family: Avenir-Heavy, Avenir, Arial, sans-serif, 'Microsoft YaHei';
}

.ff-roman {
  font-family: Avenir-Roman, Avenir, Arial, sans-serif, 'Microsoft YaHei';
}

body,
#page,
input,
textarea,
body .pure-g [class*='pure-u'],
pre {
  font-family: Avenir-Book, Arial, sans-serif, 'Microsoft YaHei';
  // Helvetica
}
body {
  color: #333;
  word-break: break-word;
}
.uppercase {
  text-transform: uppercase;
}


#page-row(@space) {
  .page-row {
    padding-left: @space;
    padding-right: @space;
    max-width: @maxWidth + @space * 2;
  }

  .nullify-page-row {
    margin-left: -@space;
    margin-right: -@space;
  }
}

#page-row(15px);

.page-row {
  margin-left: auto;
  margin-right: auto;
}

.page-row--mobile {
  width: 100%;
  max-width: 10rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-left: auto;
  margin-right: auto;
}
.nullify-page-row--mobile {
  margin-left: -0.4rem;
  margin-right: -0.4rem;
}

.max-width--mobile {
  max-width: 10rem;
  .app--md & {
    max-width: 100%;
  }
}

.app-body {
  min-height: 50vh;
}

.swiper-lazy-preloader {
  border-color: @color-theme !important;
  border-top-color: transparent !important;
}

.intercom-launcher-frame {
  z-index: 9000 !important; /* 不应该把modal遮挡 */
}

html {
  min-width: 320px;
}
body {
  background-color: #fff;

  &[data-page-theme='gray'] {
    background-color: @color-bg-gray;
  }
}

// 业务公用
.common-monthly {
  font-size: 14px;
  padding: 0.54em 0.85em;
  background: fadeout(@color-theme, 84%);
  border-radius: 2px;
  line-height: normal;
  font-family: Avenir-Heavy, Avenir, Arial, sans-serif, 'Microsoft YaHei';

  &.color-gray {
    background: fadeout(@color-gray, 84%);
  }
}

.custom-scroll-bar {
  /* 滚动槽 */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
}

.app--md {
  #page-row(26px);
}

.app--lg {
  #page-row(32px);
}

.app--xl {
  #page-row(38px);
}

.app--max {
  #page-row(42px);
}

.div-line {
  height: 1px;
  background: @color-border;
}

.car-cover-bg {
  background: linear-gradient(360deg, #f6f6f6 0%, #e2e2e2 100%);
}
