.counter {
  @iconWidth: 32px;

  height: 100%;
  position: relative;

  .counter__input {
    padding-right: @iconWidth;
  }

  .counter__inc,
  .counter__dec {
    position: absolute;
    top: 0;
    right: 0;
    width: @iconWidth;
    height: 50%;
    cursor: pointer;
  }

  .counter__dec {
    top: auto;
    bottom: 0;
  }
}
