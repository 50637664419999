@import "./_variables.less";

body, #page, input, textarea, pre {font-family: Arial, sans-serif, 'Microsoft YaHei';line-height: 1.5;word-break: break-all;}
body, #page, input, textarea {background: #fff;font-size: 14px;}
html, body {height: 100%;box-sizing: border-box;}
#page { min-height: 100%;box-sizing: border-box; }
#page.is-android {font-family: miui, 'Microsoft YaHei' !important;}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html.lock {
  // overflow: hidden; // 该方案的话在移动端相当于没有锁定高度
  // #page {height: auto;} // 不采用该方案是因为#page为flex布局，将会收到巨大影响
  &, body, #page {overflow: hidden;} // 该方案会将页面高度彻底锁死，以致页面的setScrollTop(xxx)无效
}

body {
  a {text-decoration: none;color: inherit;}
  input, textarea {
    word-break: break-all;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-transition-delay: 99999s;
      -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
    }
  }
  h1, h2, h3, h4, h5, h6 {font-weight: normal;}

  // 取消chrome下input和textarea的聚焦边框：
  input,button,select,textarea{outline:none}
  // 取消chrome下textarea可拖动放大：
  textarea{resize:none}
  // 最后，写在一起，重置input和textarea的默认样式：
  input,button,select,textarea{outline:none}
  input[type=button]{-webkit-appearance:none;outline:none}
  input[readonly] {user-select: none;ime-mode: disabled;}
  textarea{resize:none}
  &, input, textarea, pre, h1, h2, h3, h4, h5, h6, ol, ul, li, div, p, a, img {
    padding: 0;margin: 0;-webkit-tap-highlight-color:transparent;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
  }
}
