.c-toast-wrap {
  display: inline-block;
  max-width: 94%;
  z-index: 99999;
}

.c-toast {
  transition: opacity 0.3s;
  opacity: 0;
  background: #434343;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  color: #fff;
  padding: 30px;
}

.c-toast--show {
  opacity: 1;
}

.c-toast--hide {
  opacity: 0;
}

// 预置一些toast的公共样式
.c-toast--fade {
  background: rgba(67, 67, 67, 0.8);
}

.c-toast--default {
  width: 352px;
}

.app--md {
  .c-toast--default {
    width: 470px;
  }
}

.toast__close {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 10px;
  right: 4px;
  font-size: 22px;
  color: #fff;
}

.toast__icon {
  width: 56px;
  height: 56px;
  margin-bottom: 10px;
}

.toast__btn {
  margin: 15px 10px 0;
}

.toast__title {
  font-size: 17px;
  font-weight: bold;
  color: inherit;

  + .toast__p {
    margin-top: 6px;
  }
}

.toast__p {
  font-size: 12px;
  line-height: 1.5;
}

.toast__title,
.toast__p {
  text-align: left;
}

.app--md {
  .toast__close {
    width: 32px;
    height: 32px;
    font-size: 26px;
    top: 10px;
    right: 6px;
  }

  .toast__icon {
    width: 74px;
    height: 74px;
    margin-bottom: 12px;
  }

  .toast__btn {
    margin: 20px 15px 0;
  }

  .toast__title {
    font-size: 22px;

    + .toast__p {
      margin-top: 10px;
    }
  }

  .toast__title,
  .toast__p {
    text-align: center;
  }
}
